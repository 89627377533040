<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            :clearable="true"
            placeholder="加油站（可输入）"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-input v-model="searchParams.cpu_code" clearable placeholder="监控微处理器编号" />
        </el-form-item> -->
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <!-- @actionEvent="handleAction" -->
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #status="{ scope }">
        <span :class="scope.row.status == 0 ? 'text-info' : 'text-danger'">{{
          statusMap[scope.row.status]
        }}</span>
      </template>
      <template #error_count="{ scope }">
        <div style="display: flex; justify-content: center;">
          <el-tag
            type="danger"
            size="mini"
            style="display: inline-block; padding: 0 16px; box-sizing: border-box; text-align: center; cursor: pointer;"
            @click="handleView(scope.row)"
          >
            {{ scope.row.error_count }}
          </el-tag>
        </div>
      </template>
      <template #actions="{ scope }">
        <el-popconfirm
          title="确定要解锁吗？"
          @onConfirm="handleAction(scope.row, 'unlock')"
        >
          <el-button
            slot="reference"
            size="mini"
            type="primary"
            :disabled="!scope.row.status"
          >
            解锁
          </el-button>
        </el-popconfirm>
      </template>
    </default-table>
    <el-dialog></el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import { QueryLockList, ExportLockList, setUnlock } from '@/api/lock';

import mixins from '@/mixins';

export default {
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      tableLoading: true,
      searchParams: {
        site_id: ''
        // cpu_code: ''
      },
      statusMap: ['未锁机', '已锁机'],
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 64,
          fixed: true,
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '加油站名称',
          key: 'site_name',
          fixed: true,
          minWidth: 180
        },
        {
          title: '加油机名称',
          key: 'tanker_name',
          minWidth: 120
        },
        {
          title: '端口编号',
          align: 'center',
          key: 'port'
        },
        {
          title: '监控微处理号',
          key: 'cpu_code',
          minWidth: 105
        },
        {
          title: '异常数量',
          key: 'error_count',
          align: 'center',
          scopedSlots: {
            customRender: 'error_count'
          }
        },
        {
          title: '状态',
          key: 'status',
          align: 'center',
          extra: ['未锁机', '已锁机'],
          extraType: 'text_lock',
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          title: '锁机时间',
          key: 'time'
        },
        {
          title: '操作',
          fixed: 'right',
          key: 'actions',
          align: 'center',
          width: 80,
          // extra: [{ name: 'unlock', label: '解锁' }]
          scopedSlots: {
            customRender: 'actions'
          }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      }
    };
  },
  mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleAction(d, t) {
      console.log(d);
      switch (t) {
        case 'unlock':
          this.handleUnlock(d);
          break;
        default:
          this.handleJump(d);
          break;
      }
    },
    handleJump(d) {
      // console.log(d);
      const { site_id, tanker_id } = d;
      this.$store
        .dispatch('app/setCurrentMenu', '/gap_lock/record')
        .then(() => {
          this.$router.push({
            path: '/gap_lock/record',
            query: { site_id, tanker_id }
          });
        });
    },
    handleUnlock(data) {
      const { tanker_id } = data;
      setUnlock({ tanker_id }).then(res => {
        const { code, msg } = res;
        if (code === 0) {
          this.handleQuery({ current: 1, pageSize: 10 });
          this.$message({
            message: '解锁成功',
            type: 'success'
          });
        } else {
          this.$message({
            message: msg,
            type: 'error'
          });
        }
      });
    },
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      QueryLockList(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };

      ExportLockList(params)
        .then(res => {
          this.$ExportFile(res, this.title);
          // const { code, msg } = res;
          // if (code !== 0) {
          //   this.$message({
          //     message: msg || '导出失败',
          //     type: 'error'
          //   });
          // }
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    handleView(e) {
      const { site_id, tanker_id, cpu_code } = e;
      this.$store
        .dispatch('app/setCurrentMenu', '/gap_lock/record')
        .then(() => {
          this.$router.push({
            path: '/gap_lock/record',
            query: { site_id, tanker_id, cpu_code }
          });
        });
    },
    activeRouter(row) {
      console.log(row);
    }
  }
};
</script>

<style lang="scss" scoped></style>
