import request from '@/utils/request';

export function QueryLockList(params) {
  return request({
    url: '/lock_mackine/data',
    method: 'get',
    params
  });
}

export function ExportLockList(params) {
  return request({
    url: '/lock_mackine/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}
export function QueryLockHistoryList(params) {
  return request({
    url: '/lock_mackine/error_history_list',
    method: 'get',
    params
  });
}

export function ExportLockHistoryList(params) {
  return request({
    url: '/lock_mackine/error_history_export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

/**
 * 解锁加油机接口
 * @param {*} params { tanker_id: 12 }
 */
export function setUnlock(params) {
  return request({
    url: '/lock_mackine/tanker_unlock',
    method: 'get',
    params
  });
}
